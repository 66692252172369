import AutoNumeric from 'autonumeric'
import Store from '@/store'
import moment from 'moment'

/**
 * Formats a phone number.
 * Example: 123-456-7890 => (123) 456-78-90
 */
export const formatPhone = function(val) {
  if (!val) return val
  let phone = val.replace(/[^0-9]/g, '')
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2 $3 $4')
}

export const formatCurrency = function(val) {
  if (val === '' || val === null) return '-'
  let priceValue = parseFloat(val)
  const isBuggyValue = priceValue > -1 && priceValue < 0
  if (!isNaN(priceValue)) {
    if (isBuggyValue) {
      priceValue = Math.abs(priceValue)
    }
    const config = Store.getters['Settings/currencyOptions']
    priceValue = AutoNumeric.format(priceValue, config)
    if (isBuggyValue) {
      priceValue = `-${priceValue}`
    }
  } else {
    return val
  }
  return priceValue
}

export const formatCurrency2Digits = function(val) {
  if (val === '' || val === null) return '-'
  let priceValue = parseFloat(val)
  const isBuggyValue = priceValue > -1 && priceValue < 0
  if (!isNaN(priceValue)) {
    if (isBuggyValue) {
      priceValue = Math.abs(priceValue)
    }
    const config = Object.assign({}, Store.getters['Settings/currencyOptions'])
    config.decimalPlaces = 2
    priceValue = AutoNumeric.format(priceValue, config)
    if (isBuggyValue) {
      priceValue = `-${priceValue}`
    }
  } else {
    return val
  }
  return priceValue
}

export const formatCurrency3Digits = function(val) {
  if (val === '' || val === null) return '-'
  let priceValue = parseFloat(val)
  const isBuggyValue = priceValue > -1 && priceValue < 0
  if (!isNaN(priceValue)) {
    if (isBuggyValue) {
      priceValue = Math.abs(priceValue)
    }
    const config = Object.assign({}, Store.getters['Settings/currencyOptions'])
    config.decimalPlaces = 3
    priceValue = AutoNumeric.format(priceValue, config)
    if (isBuggyValue) {
      priceValue = `-${priceValue}`
    }
  } else {
    return val
  }
  return priceValue
}

export const formatCurrency6Digits = function(val) {
  if (val === '' || val === null) return '-'
  let priceValue = parseFloat(val)
  const isBuggyValue = priceValue > -1 && priceValue < 0
  if (!isNaN(priceValue)) {
    if (isBuggyValue) {
      priceValue = Math.abs(priceValue)
    }
    const config = Object.assign({}, Store.getters['Settings/currencyOptions'])
    config.decimalPlaces = 6
    priceValue = AutoNumeric.format(priceValue, config)
    if (isBuggyValue) {
      priceValue = `-${priceValue}`
    }
  } else {
    return val
  }
  return priceValue
}

export const formatCurrencyWithoutSmybol = function(val) {
  let priceValue = parseFloat(val)
  const isBuggyValue = priceValue > -1 && priceValue < 0
  if (!isNaN(priceValue)) {
    if (isBuggyValue) {
      priceValue = Math.abs(priceValue)
    }
    const config = Store.getters['Settings/currencyOptions']
    priceValue = AutoNumeric.format(priceValue, config)
    if (isBuggyValue) {
      priceValue = `-${priceValue}`
    }
  } else {
    return val
  }
  return priceValue
}

export const percentFixed = function(val) {
  if (!val) val = 0
  return formatCurrency2Digits(val)
}

export const dateFormat = function(val, format = 'DD.MM.YYYY') {
  return moment(val).format(format)
}

export default {
  percentFixed,
  formatPhone,
  formatCurrency,
  formatCurrency2Digits,
  formatCurrency3Digits,
  formatCurrency6Digits,
  formatCurrencyWithoutSmybol,
  dateFormat
}
