import Vue from 'vue'
import Router from 'vue-router'
import authMiddleware from './auth'
import { isDev, isTest, isProd } from '@/utils/helper'

// Pages
import Auth from '@/view/pages/Auth/route'
import Settings from '@/view/pages/Settings/route'
import Dashboard from '@/view/pages/Dashboard/route'
import Tax from '@/view/pages/Tax/route'
import Group from '@/view/pages/Group/route'
import Supplier from '@/view/pages/Supplier/route'
import Stock from '@/view/pages/Stock/route'
import Units from '@/view/pages/Units/route'
import Stores from '@/view/pages/Stores/route'
import PriceQuote from '@/view/pages/PriceQuote/route'
import Despatch from '@/view/pages/Despatch/route'
import Inventories from '@/view/pages/Inventory/route'
import Recipes from '@/view/pages/Recipes/route'
import MenuItems from '@/view/pages/MenuItems/route'
import OnHand from '@/view/pages/OnHand/route'
import Reports from '@/view/pages/Reports/route'
import Transfer from '@/view/pages/Transfer/route'
import Waste from '@/view/pages/Wastes/route'
import Payment from '@/view/pages/Payment/route'
import Socket from '@/view/pages/Socket/route'
import Integration from '@/view/pages/Integration/route'
import Request from '@/view/pages/Requests/route'
import Lists from '@/view/pages/Lists/route'
import ScheduledJob from '@/view/pages/ScheduledJob/route'
import Production from '@/view/pages/Production/route'
import OrderIntegrationStatus from '@/view/pages/OrderIntegrationStatus/route'
import OptionalFields from '@/view/pages/OptionalFields/route'
import Authorization from '@/view/pages/Authorization/route'
import OtherExpenses from '@/view/pages/OtherExpenses/route'
// import Hierarchy from '@/view/pages/Hierarchy/route/index.js'
import Reportings from '@/view/pages/Reportings/route'
import MenuItemsSettings from '@/view/pages/MenuItemsSettings/route'
import httpErrors from '@/view/pages/httperrors/route'
import ApproveOperations from '@/view/pages/ApproveOperations/route'
import Yield from '@/view/pages/Yield/route'
import HelpCenter from '@/view/pages/HelpCenter/route'
import IntegrationLogs from '@/view/pages/IntegrationLogs/route'
import Exchange from '@/view/pages/Exchange/route'
import LanguageTranslations from '@/view/pages/LanguageTranslations/route'
import PasiveUser from '@/view/pages/PassiveUser/route'
import * as Sentry from "@sentry/vue";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...Auth,
    ...Settings,
    ...Dashboard,
    // ...Hierarchy,
    ...Tax,
    ...Group,
    ...Supplier,
    ...Units,
    ...Stock,
    ...Stores,
    ...PriceQuote,
    ...Despatch,
    ...OnHand,
    ...Transfer,
    ...Waste,
    ...Inventories,
    ...Recipes,
    ...MenuItems,
    ...Reports,
    ...Payment,
    ...Socket,
    ...Lists,
    ...Request,
    ...ScheduledJob,
    ...Production,
    ...OptionalFields,
    ...OrderIntegrationStatus,
    ...Integration,
    ...Reportings,
    ...MenuItemsSettings,
    ...httpErrors,
    ...Authorization,
    ...ApproveOperations,
    ...Yield,
    ...HelpCenter,
    ...IntegrationLogs,
    ...OtherExpenses,
    ...Exchange,
    ...LanguageTranslations,
    ...PasiveUser,
    {
      path: '*',
      redirect: to => {
        return {
          name: 'httpErrors',
          params: { httpCode: 404 }
        }
      }
    } // redirect to 404 in this route
  ],
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

const frontEndDomain = window.VUE_APP_FRONT_END_DOMAIN || process.env.VUE_APP_FRONT_END_DOMAIN;
const frontEndDomainPattern = new RegExp(`^${frontEndDomain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`);

if (isProd()) {
  Sentry.init({
    Vue,
    dsn: "https://823a38e06e970eb5f1a33a02cecd35ef@o127642.ingest.us.sentry.io/4507418898661376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", frontEndDomainPattern],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (isDev() || isTest()) {
  router.addRoutes([
    {
      path: '/styleguide',
      component: () => import('@/view/pages/StyleGuide/')
    }
  ])
}
router.beforeEach((to, from, next) => {
  if (to.hash !== '') {
    next({ path: to.path, query: to.query, params: to.params })
  } else {
    next()
  }
  authMiddleware(to, from, next)
})

export default router
